import dayjs from 'dayjs';

export default {
    filters: {
        dateFormat (value) {
            return value ? dayjs(value).format('YYYY-MM-DD') : '';
        },
        datetimeFormat (value) {
            return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '';
        }
    }
};
