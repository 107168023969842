<template>
    <div class="flex-grow-1">
        <div class="display-1 mb-3">{{ $t('views.grants.title') }}</div>
        <v-container class="pa-0 my-3">
            <v-card :loading="isLoading">
                <v-card-text v-if="grants.length === 0">
                    {{ $t('views.grants.noGrants') }}
                </v-card-text>
                <v-card-text v-else>
                    <v-list three-line>
                        <v-list-item v-for="item in grants" :key="item.clientId">
                            <v-list-item-avatar size="64">
                                <v-img v-if="item.clientLogoUrl" :src="item.clientLogoUrl" />
                                <v-icon v-else size="64">mdi-share-variant</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="ml-2">
                                    {{ item.clientName }} <a v-if="item.clientUrl" target="_blank" :href="item.clientUrl"></a>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <div class="ma-2">{{ item.description }}</div>
                                    <div class="ma-2">
                                        <v-chip small v-for="scope in item.identityGrantNames" :key="scope" class="mr-1">
                                            {{ scope }}
                                        </v-chip>
                                        <v-chip small v-for="scope in item.apiGrantNames" :key="scope" class="mr-1">
                                            {{ scope }}
                                        </v-chip>
                                    </div>
                                    <div class="ma-2">
                                        <span>{{ $t('views.grants.created') }}: {{ item.created | datetimeFormat }}</span>
                                        <span class="ml-2">{{ $t('views.grants.expires') }}: {{ item.expires | datetimeFormat }}</span>
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon @click="revoke(item)">
                                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import DateFormatMixins from '@/mixins/DateFormatMixins';
import { getGrants, revoke } from '@/api/grants';

export default {
    mixins: [DateFormatMixins],
    data () {
        return {
            isLoading: false,
            grants: []
        };
    },
    methods: {
        async revoke (item) {
            await revoke({ clientId: item.clientId });
            var idx = this.grants.indexOf(item);
            if (idx >= 0) {
                this.grants.splice(idx, 1);
            }
        }
    },
    async created () {
        this.isLoading = true;
        this.grants = await getGrants();
        this.isLoading = false;
    }
};
</script>
